import { gql, useQuery } from '@apollo/client';
import currency from 'currency.js';
import IBookingAdapter, {
  AdminBookingInput,
} from 'domain/adapters/pageDataAdapters/IBookingAdapter';
import { PaymentLogEntry } from 'domain/entities/Payment';
import { TreatmentType } from 'domain/entities/TreatmentType';
import Booking, {
  BookingStatus,
  BookingSort,
  PriceComponentType,
  BookingForExpert,
} from 'domain/types/Booking';
import ExpertBooking, { ExpertBookingStatus } from 'domain/types/ExpertBooking';
import { WithTreatmentType } from 'domain/types/ExtensionTypes';
import { ISODate } from 'domain/types/IAvailability';
import { PaginatedResult, Pagination } from 'domain/types/Pagination';
import { QueryResult } from 'domain/types/QueryResult';
import { Sort } from 'domain/types/Sort';
import TimeRange from 'domain/types/TimeRange';
import { Entity, ID, TranslatableString } from 'domain/types/common';
import { useMemo } from 'react';
import client from 'utils/apollo';

const GQL_ACTIONS = {
  GET_ADMIN_BOOKINGS: gql`
    query GetAdminBookings(
      $pagination: Pagination!
      $relationshipFilter: String
      $sort: Sort!
      $statusFilter: BookingStatus
      $confirmedFilter: Boolean
      $customerFilter: String
      $timeRange: TimeRange
    ) {
      bookings(
        pagination: $pagination
        relationshipFilter: $relationshipFilter
        sort: $sort
        statusFilter: $statusFilter
        confirmedFilter: $confirmedFilter
        customerFilter: $customerFilter
        timeRange: $timeRange
      ) {
        bookings {
          address {
            locality
            postalCode
            street
            streetNumber
          }
          client
          confirmed
          clientData {
            companyName
            email
            name
            phoneNumber
          }
          customId
          createdAt
          details {
            massageTable
            notes
            pets
          }
          expert
          expertName
          id
          length
          price {
            components {
              type
              value
            }
            payout
            total
            totalTax
          }
          status
          time
          treatmentType {
            description {
              value
              lang
            }
            enabled
            id
            media {
              type
              uri
            }
            name {
              value
              lang
            }
            prices {
              afternoon {
                payout
                price
              }
              evening {
                payout
                price
              }
              length
              morning {
                payout
                price
              }
            }
          }
          updatedAt
        }
        count
      }
    }
  `,
  GET_ALL_EXPERT_BOOKINGS: gql`
    query GetAllExpertBookings(
      $pagination: Pagination!
      $relationshipFilter: String
      $sort: Sort!
      $statusFilter: ExpertBookingStatus
      $confirmedFilter: Boolean
    ) {
      allExpertBookings(
        pagination: $pagination
        relationshipFilter: $relationshipFilter
        sort: $sort
        statusFilter: $statusFilter
        confirmedFilter: $confirmedFilter
      ) {
        bookings {
          address {
            locality
            postalCode
            street
            streetNumber
          }
          client
          customId
          confirmed
          clientData {
            companyName
            email
            name
            hasPhoneNumber
          }
          createdAt
          details {
            massageTable
            notes
            pets
          }
          expert
          id
          length
          price {
            components {
              type
              value
            }
            payout
            total
            totalTax
          }
          status
          time
          treatmentType {
            description {
              value
              lang
            }
            enabled
            id
            media {
              type
              uri
            }
            name {
              value
              lang
            }
            prices {
              afternoon {
                payout
                price
              }
              evening {
                payout
                price
              }
              length
              morning {
                payout
                price
              }
            }
          }
          payoutStatus
          updatedAt
        }
        count
      }
    }
  `,
  GET_BOOKING_DETAILS: gql`
    query GetBookingDetails($bookingId: ID!) {
      bookingDetails(bookingId: $bookingId) {
        id
        address {
          locality
          postalCode
          street
          streetNumber
        }
        client
        confirmed
        customId
        clientData {
          companyName
          email
          name
          surname
          phoneNumber
          locale
        }
        createdAt
        details {
          massageTable
          notes
          pets
        }
        expert
        expertName
        expertCustomId
        length
        price {
          total
          totalTax
          payout
          components {
            type
            value
          }
        }
        status
        time
        treatmentType {
          id
          name {
            lang
            value
          }
        }
        updatedAt
        invoiceId
        payment {
          id
          method
        }
        place
        noClientNotification
        locationId
        secondExpert
        secondExpertName
        shoppingCart
        healthQuestionnaire
      }
    }
  `,
  GET_ALL_BOOKINGS: gql`
    query GetAllBookings($date: Date!, $expertId: ID) {
      expertBookings(date: $date, expertId: $expertId) {
        id
        shoppingCart
        address {
          locality
          postalCode
          street
          streetNumber
        }
        customId
        clientData {
          companyName
          email
          name
          surname
          birthday
          hasPhoneNumber
        }
        massageType {
          lang
          value
        }
        confirmed
        details {
          massageTable
          notes
          pets
        }
        length
        time
        status
      }
    }
  `,
  GET_BOOKING_BY_ID: gql`
    query GetBookingById($bookingId: ID!) {
      expertBooking(bookingId: $bookingId) {
        id
        address {
          locality
          postalCode
          street
          streetNumber
        }
        customId
        clientData {
          companyName
          email
          name
          surname
          birthday
          hasPhoneNumber
        }
        confirmed
        details {
          massageTable
          notes
          pets
        }
        length
        time
        payout
        massageType {
          lang
          value
        }
        status
        place
        secondExpertName
        healthQuestionnaire
      }
    }
  `,
  CONFIRM_BOOKING: gql`
    mutation ConfirmBooking($bookingId: ID!) {
      confirmBooking(bookingId: $bookingId)
    }
  `,
  CANCEL_BOOKING: gql`
    mutation CancelBooking($bookingId: ID!) {
      cancelBooking(bookingId: $bookingId)
    }
  `,
  GET_OCCUPIED_DAYS: gql`
    query GetOccupiedDays($rangeStart: Date!, $rangeEnd: Date!, $expertId: ID) {
      daysWithAvailabilities(rangeStart: $rangeStart, rangeEnd: $rangeEnd, expertId: $expertId)
      daysWithBookings(rangeStart: $rangeStart, rangeEnd: $rangeEnd, expertId: $expertId)
    }
  `,
  CREATE_BOOKING: gql`
    mutation CreateAdminBooking($params: CreateAdminBookingParams!) {
      createAdminBooking(params: $params) {
        shoppingCartId
      }
    }
  `,
  CANCEL_BOOKING_WITHOUT_REFUND: gql`
    mutation CancelBookingWithoutRefund($bookingId: ID!) {
      cancelBookingWithNoRefund(bookingId: $bookingId)
    }
  `,
  TRANSIT_UNPAID_BOOKING_STATUS: gql`
    mutation TransitUnpaidBookingStatus($bookingId: ID!) {
      transitUnpaidStatus(bookingId: $bookingId)
    }
  `,
};

interface QueryBookingDetails {
  id: ID;
  address: {
    locality: string;
    postalCode: string;
    street: string;
    streetNumber: string;
  };
  customId: string;
  clientData: {
    companyName?: string;
    email: string;
    surname: string;
    birthday: ISODate;
    name: string;
    phoneNumber: string;
  };
  createdAt: Date;
  confirmed: boolean;
  details: {
    massageTable?: boolean | number;
    notes?: string;
    pets?: string;
  };
  expert: ID;
  expertName: string;
  expertCustomId: string;
  length: number;
  price: {
    total: string;
    totalTax: string;
    payout: string;
    components: {
      type: PriceComponentType;
      value: string;
    }[];
  };
  status: BookingStatus;
  time: Date;
  treatmentType: {
    id: ID;
    name: TreatmentType['name'];
  };
  updatedAt: Date;
  invoiceId?: string;
  payment: {
    id: string;
    method: string;
    vendorData: Object;
    log: PaymentLogEntry[];
  };
  healthQuestionnaire: string;
}

interface QueryExpertBooking {
  id: ID;
  address: {
    locality: string;
    postalCode: string;
    street: string;
    streetNumber: string;
  };
  customId: string;
  clientData: {
    companyName?: string;
    email: string;
    name: string;
    surname: string;
    birthday: ISODate;
    phoneNumber: string;
  };
  confirmed: boolean;
  details: {
    massageTable?: boolean | number;
    notes?: string;
    pets?: string;
  };
  length: number;
  time: Date;
  payout: number;
  massageType: TranslatableString;
  status: ExpertBookingStatus;
}

const BookingGQLAdapter: IBookingAdapter = {
  useExpertBookings(
    statusFilter: BookingStatus,
    relationshipFilter: string,
    confirmedFilter: boolean | 'all',
    pagination: Pagination,
    sort: Sort<BookingSort>,
  ): QueryResult<PaginatedResult<{ bookings: Entity<WithTreatmentType<BookingForExpert>>[] }>> {
    const query = useQuery(GQL_ACTIONS.GET_ALL_EXPERT_BOOKINGS, {
      variables: {
        pagination,
        relationshipFilter,
        statusFilter: statusFilter || null,
        confirmedFilter: confirmedFilter !== 'all' ? confirmedFilter : null,
        ...(sort
          ? {
              sort: {
                field: sort.field,
                order: sort.order,
              },
            }
          : {}),
      },
    });
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.allExpertBookings
          ? {
              data: {
                count: query.data.allExpertBookings?.count || 0,
                bookings:
                  query.data.allExpertBookings?.bookings?.map((booking) => ({
                    ...booking,
                    price: {
                      total: currency(booking.price.total),
                      totalTax: currency(booking.price.totalTax),
                      payout: currency(booking.price.payout),
                      components: booking.price.components.map((comp) => ({
                        ...comp,
                        value: currency(comp.value),
                      })),
                    },
                  })) || [],
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },
  useAdminBookings(
    statusFilter: BookingStatus,
    relationshipFilter: string,
    confirmedFilter: boolean | 'all',
    pagination: Pagination,
    sort: Sort<BookingSort>,
    customerFilter: string,
    timeRange: TimeRange,
  ): QueryResult<PaginatedResult<{ bookings: Entity<WithTreatmentType<Booking>>[] }>> {
    const query = useQuery<{
      bookings: PaginatedResult<{ bookings: Entity<WithTreatmentType<Booking>>[] }>;
    }>(GQL_ACTIONS.GET_ADMIN_BOOKINGS, {
      variables: {
        pagination,
        relationshipFilter,
        statusFilter: statusFilter || null,
        confirmedFilter: confirmedFilter !== 'all' ? confirmedFilter : null,
        customerFilter,
        timeRange,
        ...(sort
          ? {
              sort: {
                field: sort.field,
                order: sort.order,
              },
            }
          : {}),
      },
    });
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.bookings
          ? {
              data: {
                count: query.data.bookings?.count || 0,
                bookings: query.data.bookings?.bookings || [],
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },
  useAdminBookingDetails(bookingId: ID): QueryResult<{
    booking: Entity<
      Omit<Booking, 'treatmentType'> & {
        expertName: string;
        treatmentTypeName: TranslatableString;
        treatmentTypeId: ID;
        expertCustomId: string;
      }
    >;
  }> {
    const query = useQuery<{ bookingDetails: QueryBookingDetails | null }>(
      GQL_ACTIONS.GET_BOOKING_DETAILS,
      {
        variables: {
          bookingId,
        },
      },
    );
    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data
          ? {
              data: {
                booking: {
                  ...query.data.bookingDetails,
                  price: {
                    total: currency(query.data.bookingDetails.price.total),
                    totalTax: currency(query.data.bookingDetails.price.totalTax),
                    payout: currency(query.data.bookingDetails.price.payout),
                    components: query.data.bookingDetails.price.components.map((comp) => ({
                      value: currency(comp.value),
                      type: comp.type,
                    })),
                  },
                  treatmentTypeName: query.data.bookingDetails.treatmentType.name,
                  treatmentTypeId: query.data.bookingDetails.treatmentType.id,
                  healthQuestionnaire: query.data.bookingDetails.healthQuestionnaire,
                },
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },
  useBookings(date: Date, expertId?: ID): QueryResult<{ bookings: ExpertBooking[] }> {
    const query = useQuery<{ expertBookings: QueryExpertBooking[] }>(GQL_ACTIONS.GET_ALL_BOOKINGS, {
      variables: {
        date,
        expertId,
      },
    });

    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.expertBookings
          ? {
              data: {
                bookings: query.data.expertBookings.map((booking) => ({
                  ...booking,
                  start: booking.time,
                })),
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  useBookingDetails(bookingId: ID): QueryResult<{ booking: ExpertBooking | null }> {
    const query = useQuery<{ expertBooking: QueryExpertBooking | null }>(
      GQL_ACTIONS.GET_BOOKING_BY_ID,
      {
        variables: {
          bookingId,
        },
      },
    );

    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data
          ? {
              data: {
                booking: {
                  ...query.data.expertBooking,
                  start: query.data.expertBooking.time,
                },
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  async confirmBooking(bookingId: ID): Promise<void> {
    await client.mutate({
      mutation: GQL_ACTIONS.CONFIRM_BOOKING,
      variables: {
        bookingId,
      },
      refetchQueries: ['GetBookingById', 'GetAllBookings'],
    });
  },

  async cancelBooking(bookingId: ID): Promise<void> {
    await client.mutate({
      mutation: GQL_ACTIONS.CANCEL_BOOKING,
      variables: {
        bookingId,
      },
      refetchQueries: ['GetBookingDetails', 'GetAdminBookings', 'GetCalendarExpertsAndLocation'],
    });
  },
  useOccupiedDays(
    rangeStart: Date,
    rangeEnd: Date,
    expertId?: ID,
  ): QueryResult<{
    daysWithBookings: Record<string, boolean>;
    daysWithAvailabilities: Record<string, boolean>;
  }> {
    const query = useQuery<{ daysWithAvailabilities: string[]; daysWithBookings: string[] }>(
      GQL_ACTIONS.GET_OCCUPIED_DAYS,
      {
        variables: {
          rangeStart,
          rangeEnd,
          expertId,
        },
      },
    );

    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.daysWithBookings || query.data?.daysWithAvailabilities
          ? {
              data: {
                daysWithAvailabilities: (query.data.daysWithAvailabilities || []).reduce<
                  Record<string, boolean>
                >((prev, dateString) => {
                  return {
                    ...prev,
                    [dateString]: true,
                  };
                }, {}),
                daysWithBookings: (query.data.daysWithBookings || []).reduce<
                  Record<string, boolean>
                >((prev, dateString) => {
                  return {
                    ...prev,
                    [dateString]: true,
                  };
                }, {}),
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  async createBooking(input: AdminBookingInput): Promise<ID> {
    const result = await client.mutate({
      mutation: GQL_ACTIONS.CREATE_BOOKING,
      variables: {
        params: input,
      },
      refetchQueries: ['GetAdminBookings', 'GetAllBookings'],
    });

    return result.data?.createAdminBooking?.shoppingCartId;
  },
  async cancelBookingWithoutRefund(bookingId: ID): Promise<void> {
    await client.mutate({
      mutation: GQL_ACTIONS.CANCEL_BOOKING_WITHOUT_REFUND,
      variables: {
        bookingId,
      },
      refetchQueries: ['GetBookingDetails', 'GetAdminBookings', 'GetCalendarExpertsAndLocation'],
    });
  },

  async transitUnpaidStatus(bookingId: ID): Promise<void> {
    await client.mutate({
      mutation: GQL_ACTIONS.TRANSIT_UNPAID_BOOKING_STATUS,
      variables: {
        bookingId,
      },
      refetchQueries: ['GetBookingDetails', 'GetAdminBookings'],
    });
  },
};

export default BookingGQLAdapter;
