import { gql, useQuery } from '@apollo/client';
import IClientAdapter from 'domain/adapters/pageDataAdapters/IClientAdapter';
import { BusinessClient } from 'domain/entities/BusinessClient';
import { Client, ClientOverview } from 'domain/entities/Client';
import { PaginatedResult, Pagination } from 'domain/types/Pagination';
import { QueryResult } from 'domain/types/QueryResult';
import { ID } from 'domain/types/common';
import { useMemo } from 'react';
import client from 'utils/apollo';

const GQL_ACTIONS = {
  GET_CLIENT_BY_ID: gql`
    query GetClientById($id: ID!) {
      client(id: $id) {
        id
        name
        surname
        birthday
        email
        phoneNumber
        companyName
        blocked
        verified
        locale
      }
    }
  `,
  GET_ALL_CLIENTS: gql`
    query GetAllClients(
      $relationFilter: String,
      $blockedFilter: Boolean,
      $verifiedFilter:Boolean,
      $pagination: Pagination!,
      $sort: Sort
    ) {
      clients(
        relationFilter: $relationFilter,
        blockedFilter: $blockedFilter,
        verifiedFilter: $verifiedFilter,
        pagination: $pagination,
        sort: $sort
      ) {
        nodes {
          id
          name
          surname
          birthday
          email
          phoneNumber
          blocked
          verified
        }
        count
      }
    }
  `,
  SET_CLIENT_BLOCKED_STATE: gql`
    mutation SetClientBlockedState($id: ID!, $blocked: Boolean!) {
      setClientBlockState(id: $id, blocked: $blocked)
    }
  `,
  CREATE_BUSINESS_CLIENT: gql`
    mutation CreateBusinessClient($input: BusinessClientCreationData!) {
      createBusinessClient(input: $input)
    }
  `,
  GET_CLIENT_OVERVIEWS: gql`
    query GetClientOverviews(
      $nameFilter: String
      $surnameFilter: String
      $companyNameFilter: String
      $pagination: Pagination!
    ) {
      clientOverview(
        nameFilter: $nameFilter
        surnameFilter: $surnameFilter
        companyNameFilter: $companyNameFilter
        pagination: $pagination
      ) {
        clients {
          id
          name
          companyName
          surname
        }
        totalClients
      }
    }
  `,
};

const ClientGQLAdapter: IClientAdapter = {
  useClientDetails(id: ID): QueryResult<{ client: Client | null }> {
    const query = useQuery<{ client: Client | null }>(GQL_ACTIONS.GET_CLIENT_BY_ID, {
      variables: {
        id,
      },
    });
    return useMemo(
      () => ({
        error: !!query.error,
        loading: query.loading,
        refetch: query.refetch,
        ...(query.data?.client
          ? {
              data: {
                client: query.data?.client,
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  useClients(
    relationFilter,
    blockedFilter,
    verifiedFilter,
    pagination,
    sort,
  ): QueryResult<{ clients: Client[]; count: number }> {
    const query = useQuery<{ clients: { nodes: Client[]; count: number } }>(
      GQL_ACTIONS.GET_ALL_CLIENTS,
      {
        variables: {
          relationFilter: relationFilter?.length >= 2 ? relationFilter : null,
          blockedFilter,
          verifiedFilter,
          pagination,
          ...(sort
            ? {
                sort: {
                  field: sort.field,
                  order: sort.order,
                },
              }
            : {}),
        },
      },
    );
    return useMemo(
      () => ({
        loading: query.loading,
        ...(query.data
          ? {
              data: {
                clients: query.data?.clients?.nodes || [],
                count: query.data?.clients?.count || 0,
              },
            }
          : {}),
        refetch: query.refetch,
        error: !!query.error,
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  async setClientBlockedState(id: ID, blocked: boolean): Promise<void> {
    await client.mutate({
      mutation: GQL_ACTIONS.SET_CLIENT_BLOCKED_STATE,
      variables: {
        id,
        blocked,
      },
      refetchQueries: ['GetAllClients', 'GetClientById'],
    });
  },

  async createBusinessClient(data: BusinessClient): Promise<void> {
    await client.mutate({
      mutation: GQL_ACTIONS.CREATE_BUSINESS_CLIENT,
      variables: {
        input: {
          companyName: data.companyName,
          email: data.email,
          locale: data.locale,
          name: data.name,
          phoneNumber: data.phoneNumber,
          surname: data.surname,
        },
      },
    });
  },

  useClientOverviews(
    filters: { nameFilter?: string; surnameFilter?: string; companyNameFilter?: string },
    pagination: Pagination,
  ): QueryResult<PaginatedResult<{ clients: ClientOverview[] }>> {
    const { companyNameFilter, nameFilter, surnameFilter } = filters;
    const query = useQuery(GQL_ACTIONS.GET_CLIENT_OVERVIEWS, {
      variables: {
        nameFilter,
        surnameFilter,
        companyNameFilter,
        pagination,
      },
    });

    return useMemo(
      () => ({
        error: !!query.error,
        loading: query.loading,
        refetch: query.refetch,
        ...(query.data
          ? {
              data: {
                count: query.data.clientOverview.totalClients,
                clients: query.data.clientOverview.clients,
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },
};

export default ClientGQLAdapter;
