import { QueryTuple, gql, useLazyQuery, useQuery } from '@apollo/client';
import currency from 'currency.js';
import IVoucherAdapter, {
  VoucherQueryOptions,
  VoucherSort,
} from 'domain/adapters/pageDataAdapters/IVoucherAdapter';
import Voucher, { VoucherStatus } from 'domain/entities/Voucher';
import { PaginatedResult, Pagination } from 'domain/types/Pagination';
import { QueryResult } from 'domain/types/QueryResult';
import { Sort } from 'domain/types/Sort';
import { Entity, ID } from 'domain/types/common';
import moment from 'moment';
import { useMemo } from 'react';
import client from 'utils/apollo';

const GQL_ACTIONS = {
  GET_ALL_VOUCHERS: gql`
    query GetAllVouchers(
      $statusFilter: VoucherStatus
      $pagination: Pagination!
      $sort: Sort
      $balanceMaxFilter: Int
      $balanceMinFilter: Int
      $expiryFilter: Boolean
      $usedFilter: Boolean
      $codeFilter: String
      $emailFilter: String
    ) {
      vouchers(
        statusFilter: $statusFilter
        sort: $sort
        pagination: $pagination
        balanceMaxFilter: $balanceMaxFilter
        balanceMinFilter: $balanceMinFilter
        expiryFilter: $expiryFilter
        usedFilter: $usedFilter
        codeFilter: $codeFilter
        emailFilter: $emailFilter
      ) {
        count
        nodes {
          id
          balance
          buyer
          buyerData {
            companyName
            email
            name
            surname
            phoneNumber
          }
          code
          expiresAt
          status
          value
          createdAt
        }
      }
    }
  `,
  GET_VOUCHER_BY_ID: gql`
    query GetVoucherById($voucherId: ID!) {
      voucher(voucherId: $voucherId) {
        balance
        buyer
        buyerData {
          companyName
          email
          name
          birthday
          surname
          phoneNumber
        }
        code
        expiresAt
        id
        message
        status
        value
      }
    }
  `,
  CREATE_VOUCHER: gql`
    mutation CreateVoucher($input: CreateVoucherInput!) {
      createVoucher(input: $input)
    }
  `,
  UPDATE_VOUCHER: gql`
    mutation UpdateVoucher($voucherId: ID!, $input: UpdateVoucherInput!) {
      updateVoucher(voucherId: $voucherId, input: $input)
    }
  `,

  UPDATE_VOUCHERS_BALANCE: gql`
    mutation UpdateVouchersBalance($updateValue: Int!, $voucherCodes: [String!]!) {
      updateVouchersBalance(updateValue: $updateValue, voucherCodes: $voucherCodes) {
        code
        balance
        value
      }
    }
  `,

  GET_VOUCHERS_FOR_CSV: gql`
    query GetVouchersForCSV(
      $statusFilter: VoucherStatus
      $pagination: Pagination!
      $sort: Sort
      $balanceMaxFilter: Int
      $balanceMinFilter: Int
      $expiryFilter: Boolean
      $usedFilter: Boolean
      $codeFilter: String
      $emailFilter: String
    ) {
      vouchers(
        statusFilter: $statusFilter
        sort: $sort
        pagination: $pagination
        balanceMaxFilter: $balanceMaxFilter
        balanceMinFilter: $balanceMinFilter
        expiryFilter: $expiryFilter
        usedFilter: $usedFilter
        codeFilter: $codeFilter
        emailFilter: $emailFilter
      ) {
        nodes {
          code
          balance
        }
      }
    }`,
};

const VoucherGQLAdapter: IVoucherAdapter = {
  useVouchers(options: {
    sort?: Sort<VoucherSort>;
    pagination: Pagination;
    statusFilter?: VoucherStatus;
    expiryFilter?: boolean;
    voucherMinBalanceFilter?: number;
    voucherMaxBalanceFilter?: number;
    usedFilter?: boolean;
    codeFilter?: string;
    emailFilter?: string;
  }): QueryResult<PaginatedResult<{ vouchers: Entity<Voucher>[] }>> {
    const query = useQuery(GQL_ACTIONS.GET_ALL_VOUCHERS, {
      variables: {
        sort: options.sort,
        pagination: options.pagination,
        statusFilter: options.statusFilter,
        expiryFilter: options.expiryFilter,
        balanceMaxFilter: options.voucherMaxBalanceFilter,
        balanceMinFilter: options.voucherMinBalanceFilter,
        usedFilter: options.usedFilter,
        codeFilter: options.codeFilter?.length > 2 ? options.codeFilter : null,
        emailFilter: options.emailFilter,
      },
    });

    return useMemo(
      () => ({
        loading: query.loading,
        refetch: query.refetch,
        error: !!query.error,
        ...(query.data?.vouchers
          ? {
              data: {
                count: query.data.vouchers.count,
                vouchers: query.data.vouchers.nodes.map((v) => ({
                  ...v,
                  expiresAt: moment(v.expiresAt).toDate(),
                  value: currency(v.value),
                  balance: currency(v.balance),
                })),
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  useVoucher(voucherId: ID): QueryResult<{ voucher: Entity<Voucher> }> {
    const query = useQuery(GQL_ACTIONS.GET_VOUCHER_BY_ID, {
      variables: {
        voucherId,
      },
    });

    return useMemo(
      () => ({
        refetch: query.refetch,
        loading: query.loading,
        error: !!query.error,
        ...(query.data?.voucher
          ? {
              data: {
                voucher: {
                  ...query.data.voucher,
                  balance: currency(query.data.voucher.balance),
                  value: currency(query.data.voucher.value),
                  expiresAt: moment(query.data.voucher.expiresAt).toDate(),
                },
              },
            }
          : {}),
      }),
      [query.data, query.error, query.loading, query.refetch],
    );
  },

  async createVoucher(
    voucher: Omit<Voucher, 'code' | 'balance' | 'buyerData'> & {
      balance?: currency;
      buyerData?: Voucher['buyerData'];
      disableVoucherGeneration: boolean;
    },
    amount?: number,
  ): Promise<ID> {
    const response = await client.mutate<{ createVoucher: ID }>({
      mutation: GQL_ACTIONS.CREATE_VOUCHER,
      variables: {
        input: {
          amount,
          balance: voucher.balance?.toString(),
          buyer: voucher.buyer,
          buyerData: voucher.buyerData,
          expiresAt: moment(voucher.expiresAt).toISOString(),
          message: voucher.message,
          status: voucher.status,
          value: voucher.value.toString(),
          disableVoucherGeneration: voucher.disableVoucherGeneration,
        },
      },
      refetchQueries: ['GetAllVouchers'],
    });
    if (!response.data) {
      throw new Error('Error while creating voucher');
    }
    return response.data.createVoucher;
  },

  async updateVoucher(voucherId: ID, input: Partial<Voucher>): Promise<void> {
    await client.mutate({
      mutation: GQL_ACTIONS.UPDATE_VOUCHER,
      variables: {
        input: {
          balance: input.balance?.toString(),
          message: input.message,
          status: input.status,
          expiresAt: input.expiresAt ? moment(input.expiresAt).toISOString() : undefined,
          clientData: input.buyerData,
        },
        voucherId,
      },
      refetchQueries: ['GetAllVouchers', 'GetVoucherById'],
    });
  },

  async updateVouchersBalance(
    updateValue,
    voucherCodes,
  ): Promise<
    {
      code: string;
      value: string;
      balance: string;
    }[]
  > {
    const date = await client.mutate({
      mutation: GQL_ACTIONS.UPDATE_VOUCHERS_BALANCE,
      variables: {
        updateValue: Number(updateValue),
        voucherCodes,
      },
    });

    return date.data.updateVouchersBalance;
  },

  useVouchersForCSV(
    total: number,
    options: VoucherQueryOptions,
  ): QueryTuple<
    {
      vouchers: {
        nodes: {
          code: string;
          balance: currency;
        }[];
      };
    },
    VoucherQueryOptions
  > {
    return useLazyQuery(GQL_ACTIONS.GET_VOUCHERS_FOR_CSV, {
      variables: {
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
        pagination: {
          offset: 0,
          limit: total,
        },
        statusFilter: options.statusFilter,
        expiryFilter: options.expiryFilter,
        balanceMaxFilter: options.voucherMaxBalanceFilter,
        balanceMinFilter: options.voucherMinBalanceFilter,
        usedFilter: options.usedFilter,
        codeFilter: options.codeFilter?.length > 2 ? options.codeFilter : null,
        emailFilter: options.emailFilter,
      },
    });
  },
};

export default VoucherGQLAdapter;
