import { colors, createTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground,
} from '@material-ui/core/styles/createPalette';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createTheme';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import { THEMES } from 'app_constants';
import _ from 'lodash';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
  typography: MuiTheme['typography'];
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: { root: { minWidth: 32 } },
    MuiChip: { root: { backgroundColor: 'rgba(0,0,0,0.075)' } },
  },
};

const COLOR_PRIMARY = '#9c5239';

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#706966',
          },
        },
      },
      MuiSwitch: {
        colorSecondary: {
          '&$checked': {
            color: COLOR_PRIMARY,
          },
        },
        track: {
          '$checked$checked + &': {
            backgroundColor: COLOR_PRIMARY,
          },
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: COLOR_PRIMARY,
          },
        },
      },
    },
    palette: {
      type: 'light',
      action: { active: '#706966;' },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
      },
      myCustomColor: {
        main: '#4caf50',
        contrastText: '#fff',
      },
      primary: { main: COLOR_PRIMARY },
      secondary: { main: '#f7f2ee' },
      sand: { main: '#ffd550' },
      text: {
        primary: '#414141',
        secondary: '#706966',
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d',
      },
      primary: { main: '#111311' },
      secondary: { main: '#1D1711' },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4',
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction }),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
};
